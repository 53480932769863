import React, { useEffect, useState } from 'react'
import { graphql, Link } from "gatsby"
import tw, { styled, css } from 'twin.macro'
import { createHTML } from '../utils'
import _ from 'lodash'

import Layout from "../components/Layout"
import Section from '../components/Section'
import Hero from '../components/Hero'
import ContactUs from '../components/ContactUs'

import SvgNoData from '../../static/img/svg/no-data.svg'
import DropdownChevron from '../../static/img/svg/down-chevron.svg'

const DropdownArrow = styled(DropdownChevron)`
    ${tw`
        inline-block
        ml-auto w-4 h-auto
    `}
`

const GridContainer = styled.div`
    ${tw`
        w-full
    `}

    ${({ hasSearch }) => hasSearch && css`
        ${tw`
            lg:grid gap-12
        `}

        grid-template-columns: 15rem 1fr;       
    `}
`

const CardGridList = styled.ul`
    ${tw`
        grid gap-4 
        grid-cols-1 sm:grid-cols-2 md:grid-cols-3
    `}

    height: max-content;
`

const Card = styled.div`
    ${tw`
        flex flex-col flex-wrap
        justify-center items-center 
        relative 
        w-full h-full
        rounded-md shadow-lg
        transition-all duration-300
        overflow-hidden
    `}

    &:hover {
        transform: scale(1.02);

        ${tw`
            shadow-xl
        `}
    }
`

const ImgContainer = styled.div`
    ${tw`
        w-full
        py-20
        flex justify-center items-center
    `}

    background-color:  ${props => props.bgColor || "rgba(209, 213, 219, 1)"};

    > svg, img {
        ${tw`
            w-16 h-16
        `}
    }
`

const TextContainer = styled.div`
    ${tw`
        w-full 
        p-4 pt-6
        flex-grow
        flex flex-col    //uncomment if want the pills to stick to the bottom of the card
    `}

    > h5 {
        ${tw`
            font-bold
            mb-2
        `}
    }

    > p {
        ${tw`
            text-sm
            mb-8
        `}
    }
`

const PillsContainer = styled.div`
    ${tw`
        mt-auto
        flex flex-wrap justify-start gap-2
    `}
`

const Pill = styled.span`
    ${tw`
        rounded-full 
        text-white text-sm
        py-0.5 px-1
        inline-block
        overflow-ellipsis
        overflow-hidden whitespace-nowrap
    `}

    font-size: 0.6rem;
    background: darkorange; 
`

const SearchContainer = styled.div`
    ${tw`
        // bg-red-400
        mb-10 lg:mb-0
    `}
`

const SearchBar = styled.input`
    ${tw`
        p-1
        w-full
        border-2
    `}
`

const EmptyResultsContainer = styled.div`
    ${tw`
        col-span-full
        p-10
        border-2
        w-full 
        text-center
    `}

    > svg, img {
        ${tw`
            mx-auto mb-4
            h-24 w-auto
        `}
    }

    > p {
        ${tw`
            text-xl
            font-semibold
        `}
    }
`

const SearchFiltersContent = styled.div`
    ${tw`
        grid   
    `}

    @media (max-width: 1024px) {
        ${tw`
            origin-top transform scale-y-0 
            max-h-0
            // transition-all duration-300
        `}

        ${({ open }) => open && tw`     
            transform scale-y-100
            max-h-full
        `}
    }
`

const SearchFiltersHeader = styled.div`
    ${tw`
        lg:mb-4 mt-8
        flex flex-wrap
    `}
`

const SfhClickableDropdown = styled.div`
    ${tw`
        flex
        w-full lg:w-auto
    `}

    > p:first-child {
        ${tw`
            font-semibold
            uppercase
        `}
    }

    > ${DropdownArrow} {
        ${tw`
            lg:hidden
            ml-auto
        `}
    }
`

const SfhResetOuter = styled.div`
    ${tw`
        lg:ml-auto
        w-full lg:w-auto
        mt-2 lg:mt-0
        text-gray-400

        origin-top transform scale-y-0
        max-h-0
    `}

    ${({ open }) => open && tw`     
        transform scale-y-100
        max-h-full
        mt-2
    `}
    
    > p {
        ${tw`
            w-max
        `}
    }
`

const FilterCategory = styled.div`
    ${tw`
        pt-4 mt-4
        border-t
    `}
`

const FcHeader = styled.div`
    ${tw`
        flex
        cursor-pointer
    `}
`

const FcList = styled.ul`
    ${tw`
        grid
        my-0 ml-4
        // transition-all duration-300
        origin-top transform scale-y-0 
        max-h-0
    `}

    > li {
        ${tw`
            mb-2
        `}
    }

    ${({ open }) => open && tw`     
        transform scale-y-100
        max-h-full
        mt-2
    `}
`

const FilterItem = styled.label`
    > input {
        ${tw`
            mr-1
        `}     
    }
`

const MainPageTwo = ({
    data: {
        markdownRemark: {
            frontmatter: { seo, hero, cards, contactSection, searchFilterCategories }
        },
    }
}) => {
    const [mql, setMql] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryDropdownStates, setCategoryDropdownStates] = useState(new Array(searchFilterCategories.length).fill(true));
    const [searchFiltersOpen, setSearchFiltersOpen] = useState(true)
    const [filtersDict, setFiltersDict] = useState(() => {
        let dict = {};
        for (const sc of searchFilterCategories) {
            for (const type of sc.types) {
                dict[type] = false;
            }
        }

        return dict;
    })

    const filteredCards = cards.filter(filterCards);

    useEffect(() => {
        function hideSearchFilters(e) {
            if (e.matches) {
                // window is now lg or under
                setSearchFiltersOpen(false);
            } else {
                setSearchFiltersOpen(true);
            }
        }

        const mediaQueryList = window.matchMedia('(max-width: 1024px)');
        mediaQueryList.addEventListener("change", hideSearchFilters);
        setMql(mediaQueryList);

        hideSearchFilters(mediaQueryList);

        return () => {
            mediaQueryList.removeEventListener('change', hideSearchFilters);
        }
    }, [])

    function filterCards(value) {
        let activeFilters = _.keys(_.pickBy(filtersDict))

        // if it the case that there is no entered search-term nor a checked filter then return all cards
        if (searchTerm === "" && activeFilters.length === 0) {
            return value;
        }

        // if the execution goes past this point, this means either the user entered a search-term or checked a filter (or both)

        let hasFilterMatch = value.pills.some(p => activeFilters.includes(p)) ||
            value.metadata.some(md => activeFilters.includes(md));

        let hasSearchTermMatch = value.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            value.body.toLowerCase().includes(searchTerm.toLowerCase()) ||
            value.pills.find(pill => pill.toLowerCase().includes(searchTerm.toLowerCase())) ||
            value.metadata.find(md => md.toLowerCase().includes(searchTerm.toLowerCase()));

        if (searchTerm !== "" && activeFilters.length === 0) {
            // if the user entered a search-term but no filters are applied only return cards that has a search-term match
            if (hasSearchTermMatch) {
                return value;
            }
        } else if (searchTerm === "" && activeFilters.length > 0) {
            // else if the search term is left empty but a filter is checked only return cards that has a filter match
            if (hasFilterMatch) {
                return value;
            }
        } else {
            // otherwise if the user both entered a search-term and checked a filter then only return cards that pass both a search-term and a filter match
            if (hasSearchTermMatch && hasFilterMatch) {
                return value;
            }
        }
    }

    // function test() {
    //     let active = _.keys(_.pickBy(filtersDict))
    //     console.log("ACTIVE LOG:", active)
    // }

    const resetFilters = () => {
        setFiltersDict(_.mapValues(filtersDict, () => false));
    }

    const toggleInputFilter = (event) => {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        setFiltersDict(prevState => ({ ...prevState, [name]: value }))
    }

    const toggleCategoryDropdown = (idx) => () => {
        let newState = [...categoryDropdownStates]
        newState[idx] = !newState[idx]
        setCategoryDropdownStates(newState)
    }

    const toggleSearchFilters = () => {
        // only allow the search filters to be disabled/enabled for lg or under viewports
        if (mql.matches) {
            setSearchFiltersOpen(prevState => !prevState);
        }
    }

    return (
        <Layout title={seo.title} description={seo.description}>
            <Section>
                <Hero data={hero} />
            </Section>

            <Section>
                <GridContainer hasSearch>
                    <SearchContainer>
                        <SearchBar type="text" placeholder="Search..."
                            onChange={event => { setSearchTerm(event.target.value) }}
                        />
                        <SearchFiltersHeader>
                            <SfhClickableDropdown onClick={toggleSearchFilters}>
                                <p>Filter by:</p>
                                <DropdownArrow />
                            </SfhClickableDropdown>
                            <SfhResetOuter open={searchFiltersOpen}>
                                <button onClick={resetFilters}>
                                    Reset
                                </button>
                            </SfhResetOuter>
                        </SearchFiltersHeader>
                        <SearchFiltersContent open={searchFiltersOpen}>
                            {searchFilterCategories.map(({ category, types }, categoryIdx) => (
                                <FilterCategory key={categoryIdx}>
                                    <FcHeader onClick={toggleCategoryDropdown(categoryIdx)}>
                                        <p>{category}</p>
                                        <DropdownArrow />
                                    </FcHeader>
                                    <FcList open={categoryDropdownStates[categoryIdx]}>
                                        {types.map((type, typeIdx) => (
                                            <li key={typeIdx}>
                                                <FilterItem>
                                                    <input
                                                        type="checkbox"
                                                        name={type}
                                                        checked={filtersDict[type]}
                                                        onChange={toggleInputFilter}
                                                    />
                                                    {type}
                                                </FilterItem>
                                            </li>
                                        ))}
                                    </FcList>
                                </FilterCategory>
                            ))}
                        </SearchFiltersContent>
                    </SearchContainer>
                    <CardGridList>
                        {filteredCards.length === 0 && (
                            <EmptyResultsContainer>
                                <SvgNoData />
                                <p>No search results found.</p>
                            </EmptyResultsContainer>
                        )}
                        {filteredCards.length === 0 ? (
                            cards.map(({ title, body, url, icon, bgColor, pills }, cardIdx) => (
                                <li key={cardIdx}>
                                    <Link to={url}>
                                        <Card>
                                            <ImgContainer bgColor={bgColor}>
                                                <img src={icon.relativePath ? `/img/${icon.relativePath}` : icon} alt={`${title} icon`} />
                                            </ImgContainer>
                                            <TextContainer>
                                                <h5>{title}</h5>
                                                <p>{body}</p>
                                                <PillsContainer>
                                                    {pills.map((pill, pillIdx) => (
                                                        <Pill key={pillIdx}>
                                                            {pill}
                                                        </Pill>
                                                    ))}
                                                </PillsContainer>
                                            </TextContainer>
                                        </Card>
                                    </Link>
                                </li>
                            ))
                        ) : (
                            filteredCards.map(({ title, body, url, icon, bgColor, pills }, cardIdx) => (
                                <li key={cardIdx}>
                                    <Link to={url}>
                                        <Card>
                                            <ImgContainer bgColor={bgColor}>
                                                <img src={icon.relativePath ? `/img/${icon.relativePath}` : icon} alt={`${title} icon`} />
                                            </ImgContainer>
                                            <TextContainer>
                                                <h5>{title}</h5>
                                                <p>{body}</p>
                                                <PillsContainer>
                                                    {pills.map((pill, pillIdx) => (
                                                        <Pill key={pillIdx}>
                                                            {pill}
                                                        </Pill>
                                                    ))}
                                                </PillsContainer>
                                            </TextContainer>
                                        </Card>
                                    </Link>
                                </li>
                            ))
                        )}

                    </CardGridList>
                </GridContainer>
            </Section>

            {contactSection.visible &&
                <Section>
                    <ContactUs iconColor={contactSection.color} />
                </Section>
            }
        </Layout>
    )
}

export default MainPageTwo

export const pageQuery = graphql`
    query mainPageTwoById($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                seo {
                    title 
                    description
                }
                hero {
                    preheader
                    header {
                        text
                        gradient {
                            top
                            bottom
                        }
                    }
                    subheader
                    sideImages {
                        left {
                            relativePath
                        }
                        right {
                            relativePath
                        }
                    }
                }
                cards {
                    title
                    body
                    url 
                    icon {
                        relativePath
                    }
                    bgColor
                    pills
                    metadata
                }
                searchFilterCategories {
                    category
                    types
                }
                contactSection {
                    visible
                    color
                }
            }
        }
    }
`